import About from "./About";
import BuildHero from "./BuildHero";
import Hero from "./Hero";
import TeamHero from "./TeamHero";

export default function Home() {
  return (
    <>
      <Hero />
      {/* <BuildHero />
      <About />
      <TeamHero /> */}
    </>
  );
}
