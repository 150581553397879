export const MenuItems = [
  // {
  //   title: "Projects",
  //   tlink: "/projects",
  //   tTarget: "_self",
  // },
  // {
  //   title: "Services",
  //   tlink: "/services",
  //   tTarget: "_self",
  // },
  // {
  //   title: "Contact",
  //   tlink: "/contact",
  //   tTarget: "_self",
  // },
];
